import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import './assets/global.css'
import axios from 'axios'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
// 引入echarts
import * as echarts from 'echarts'

import * as auth from './components/data/cookie.js'

//顶部页面加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
//路由监听
router.beforeEach((to, from, next) => {
  NProgress.start();

  // console.log('是否需要登录才能访问')
  if (to.meta.needLogin) {
    if (auth.getAdminInfo()) {
      // console.log(auth.getAdminInfo())
      // console.log('有cookie信息')
      next();
    } else {
      // console.log('无cookie信息')
      next({
        path: '/'
      });
    }
  } else {
    next();
  }
});
//路由跳转结束
router.afterEach(() => {
  NProgress.done()
})


Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios

// 本地测试地址
// Vue.prototype.$httpUrl = 'http://localhost:8092'
// Vue.prototype.$apiHttpUrl = 'http://localhost:8092'
// Vue.prototype.$dspHttpUrl = 'http://localhost:8091'
// Vue.prototype.$emuHttpUrl = 'http://localhost:8093'

// 测试环境地址
// Vue.prototype.$httpUrl = 'http://localhost:8092'
// Vue.prototype.$apiHttpUrl = 'http://localhost:8092'
// Vue.prototype.$dspHttpUrl = 'http://localhost:8091'

// 生成地址
Vue.prototype.$httpUrl = 'https://back.appifun.com'
Vue.prototype.$dspHttpUrl = 'https://dsp.appifun.com'
Vue.prototype.$emuHttpUrl = 'https://emu.appifun.com'


Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI, { locale, size: 'small' })
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

